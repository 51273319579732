<!--
 * @Author: luoyan
 * @Date: 2021-10-29 15:21:18
 * @LastEditTime: 2023-10-17 10:18:29
 * @Description: file content 我要求购
-->
<template>
  <comprehensive>
    <div class="tobuybox">
      <div class="tobuytitle">
        <span></span>
        <p style="font-size:16px">求购信息</p>
      </div>
      <el-form
        :model="form"
        :rules="rules"
        ref="form"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-row>
          <el-col :span="12">
            <el-form-item
              label="联系人"
              prop="contactName"
            >
              <el-input v-model="form.contactName"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item
              label="联系电话"
              prop="contactPhone"
            >
              <el-input v-model="form.contactPhone"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item
          label="求购明细"
          prop="buyingInstructions"
          label-width="100px"
        >
          <el-input
            v-model="form.buyingInstructions"
            placeholder="写下您的真实需求，如：建材 螺纹钢 100吨"
            type="textarea"
            :rows="5"
            maxlength="100"
            show-word-limit
          ></el-input>
        </el-form-item>
        <el-form-item
          label="图片(最多3张)"
          prop=""
        >
          <ddmg-upload-img
            :number="3"
            v-model="form.image"
          ></ddmg-upload-img>
          <!--          <el-upload-->
          <!--            action="https://jsonplaceholder.typicode.com/posts/"-->
          <!--            list-type="picture-card"-->
          <!--            :on-preview="handlePictureCardPreview"-->
          <!--            :on-remove="handleRemove"-->
          <!--            :on-success="handleSuccess"-->
          <!--            :limit="3"-->
          <!--            :on-exceed="maxUpload"-->
          <!--            :disabled="maxUploadnum"-->
          <!--          >-->
          <!--            <i class="el-icon-plus" :disabled="maxUploadnum"></i>-->
          <!--          </el-upload>-->
          <!--          <el-dialog :visible.sync="dialogVisible">-->
          <!--            <img width="100%" :src="dialogImageUrl" alt="大大买钢" />-->
          <!--          </el-dialog>-->
        </el-form-item>
        <el-form-item>
          <el-button
            @click="submitForm('form')"
            class="btn"
            type="submit"
            style="width: 181px;height: 42px;background-color: #FF6200;color: #FFFFFF;border-radius: 0;outline:none;font-size: 16px"
          >
            确认提交
          </el-button>
        </el-form-item>
      </el-form>
    </div>
  </comprehensive>
</template>

<script>
import comprehensive from "../../components/comprehensive.vue";
import storage from "@/utils/storage";
import ddmgUploadImg from "../../components/ddmg-upload-img/index.vue";
import { mapActions } from "vuex";
export default {
  name: 'toBuy',
  components: {
    comprehensive,
    ddmgUploadImg
  },
  data () {
    return {
      form: {
        contactName: "",
        contactPhone: "",
        buyingInstructions: "",
        image: [],
        companyId: storage.getStorage("company_id")
      },
      dialogImageUrl: "",
      dialogVisible: false,
      maxUploadnum: false,
      imglist: [],
      rules: {
        contactName: [
          { required: true, message: "该内容不能为空", trigger: "blur" },
        ],
        contactPhone: [
          { required: true, message: "该内容不能为空", trigger: "blur" },
        ],
        buyingInstructions: [
          { required: true, message: "该内容不能为空", trigger: "blur" },
        ],
      },
    };
  },
  created () {
  },
  methods: {
    ...mapActions("ddmg/bid", ["toBuySubmit"]),


    addFiles (index) {
      console.log('1111', index)
    },
    deleteFiles (index) {
      console.log('1111', index)
    },
    handleRemove (file, fileList) {
      console.log(file, fileList);
    },

    handlePictureCardPreview (file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    handleSuccess (response, file, fileList) {
      console.log(response, file, fileList);
      this.imglist = fileList;
    },
    maxUpload (files, fileList) {
      console.log("33333333", files, fileList);
      this.maxUploadnum = true;
      this.$message.error("已经超过3条了");
    },

    getCookie (name) {
      //获取当前所有cookie
      var strCookies = document.cookie;
      //截取变成cookie数组
      var array = strCookies.split(";");

      //循环每个cookie
      for (var i = 0; i < array.length; i++) {
        //将cookie截取成两部分
        var item = array[i].split("=");
        //判断cookie的name 是否相等
        if (item[0].trim() == name) {
          return item[1];
        }
      }
      return null;
    },
    submitForm (formName) {
      let isToken = this.isToken()
      if (isToken) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.toBuySubmit(this.form).then((res) => {
              if (res.msgcode == 1) {
                this.$message({
                  message: "提交成功",
                  type: "success",
                });
              }
            });
          }
        });
      }
      //     // Api.startReq(
      //     //   "pc/buyer/businessProcess/buyingGoods",
      //     //   this.form,
      //     //   "post"
      //     // ).then((res) => {
      //     //   if (res.code == 1) {
      //     //     this.$message({
      //     //       message: res.message,
      //     //       type: "success",
      //     //     });
      //     //   } else {
      //     //     this.$message({
      //     //       message: res.message,
      //     //       type: "warning",
      //     //     });
      //     //   }
      //     // });
      //   } else {
      //     console.log("error submit!!");
      //     return false;
      //   }
      // });
    },
  },
};
</script>

<style lang="scss" scoped>
.tobuybox {
  max-width: 1000px;
  margin: 0 auto;
  .tobuytitle {
    font-size: 20px;
    color: #333333;
    margin-left: 19px;
    font-weight: 500;
    height: 100px;
    line-height: 100px;
    display: flex;
    align-items: center;
  }
  .tobuytitle span {
    height: 22px;
    line-height: 100px;
    width: 5px;
    background-color: royalblue;
    display: flex;
    margin-right: 20px;
  }
}
</style>
